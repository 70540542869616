@import "../styles/defaults.scss";

.about-me {
    background: $background;
    height: 100%;
}

.about-me .container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 600px;
}

.about-me .section {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.about-me .icon {
    width: 20%;
    align-self: center;
    display: flex;
    justify-content: center;
}

.about-me .text {
    width: calc(80%);
    text-align: left;
    margin: 0;
}


@import '../../styles/defaults.scss';

.menu-items {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;

    @include tablet {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        column-gap: 32px;
        margin-right: 40px;
    }
}

.menu-items .item {
    text-decoration: none;
    color: $blue90;
    margin: 0;
    margin-left: 24px;
    font-size: 24px;
    line-height: 50px;
    display: flex;
    align-items: center;    
    
    @include tablet {
        margin: 0;
        font-size: 18px;
        line-height: 0px;
    }
}


// Style View Resume button on mobile menu
.menu .open-link-button {
    font-size: 20px;
    text-align: left;
    margin-left: 0;
    margin-bottom: calc(100lvh - 100svh + 40px);
}
@import "../styles/defaults.scss";

.projects {
    background: $background;
}

.projects .cards {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
@import '../../styles/defaults.scss';

.project-card {
    width: 90%;
    margin: auto;
    border-bottom: 1px solid $blue20;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.project-card .title {
    text-align: left;
    font-size: 20px;
    margin-left: 16px;
}

.project-card .description {
    text-align: left;
    font-size: 18px;    
    margin-left: 32px;
}

.project-card .text-container {

}

.project-card .icon {
    flex-shrink: 0;
}

.project-card .tech-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    align-items: flex-start;
    margin-left: 32px;
}

.project-card .tech-item {
    background: #a8b4dfaa;
    color: $blue90;
    border-radius: 5px;
    padding: 5px 5px;
    flex-grow: 0;
}
@import "../styles/defaults.scss";

.homepage {
    height: 100vh;
    background-color: $background;
    overflow-x: visible;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage .title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.homepage .title .main {
    font-size: 48px;
    font-weight: 800;
    margin: 0;    
}

.homepage .title .main .name {
    color: $blue50;   
}


.homepage .title .sub {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
}

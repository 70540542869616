@import '../../styles/defaults.scss';

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    position: fixed;

    @include tablet {
        position: relative;
        margin-top: 24px;
    }
}

.menu-icon {
    margin-right: 24px;
}
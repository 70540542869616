
@mixin tablet {
  @media only screen and (min-width: 768px) { @content; }
}

@mixin desktop {
  @media only screen and (min-width: 1440px) { @content; }
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap');

 
$background: #ece7de;
$text: #242623;

$gray0: #000000;
$gray90: #efece7;
$gray95: #f8f3eb;
$gray100: #ffffff;

$blue05: #a8b4df;
$blue20: #718ce5;
$blue50: #4056a1;
$blue55: #384d90;
$blue90: #1b2444;

$red70: #870d27;

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: $background;
  font-family: "Lato", sans-serif;
  color: $blue90;
}

.page-title {
    font-size: 24px;
    font-weight: 300;
    width: 100%;
    color: $background;
    background-color: $blue50;
    line-height: 50px;
    margin-top: 0;
}

.content {
    max-width: 1440px;
    margin: auto;
}

.open-link-button {
    font-family: "Lato", sans-serif;
    background-color: $blue50;
    padding: 10px 20px;
    border-radius: 20px;
    color: $background;
    text-decoration: none;
    border: none;
    align-self: center;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        background-color: $blue55;
    }
}
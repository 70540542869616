@import '../../styles/defaults.scss';

.project-drawer {
    height: 100vh;
    min-width: 100vw;
    background-color: $gray95;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    @include tablet {
        min-width: 600px;
        height: 100vh;
        max-width: 95vw;
    }
}

.project-drawer .inner-container {
    overflow-y: scroll;
}

.project-drawer .drawer-content {
    padding: 0 5% 0 5%;
    display: flex;
    flex-direction: column;
}

.project-drawer .icon-row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.project-drawer .link-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
@import '../../styles/defaults.scss';

.experience-drawer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $gray95;
    overflow: hidden;

    @include tablet {
        width: 600px;
        height: 100vh;
    }
}

.experience-drawer .drawer-content {
    padding: 40px 24px;
    background-color: $gray95;
    overflow-y: scroll;
}

.experience-drawer .list {

    
}

.experience-drawer .tech-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    border: none;
}

.experience-drawer .tech-item {
    background: #a8b4dfaa;
    color: $blue90;
    border-radius: 5px;
    padding: 15px 10px;
    
}
@import '../styles/defaults.scss';

.experience {
    background: $background;
}

.experience .cards {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
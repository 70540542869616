@import '../styles/defaults.scss';

.close-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        display: none;
    }
}

.close-container .close {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    text-decoration: none;
    color: $text;
    font-size: 14px;
}

.close-container .close .close-text {
}
@import '../../styles/defaults.scss';

.image-carousel {
    
}

.images-container {
    display: flex;
    overflow: hidden;
}

.image-carousel .image {
    display: block;
    width: 100vw;
    height: auto;
    min-height: 30vh;
    object-fit: cover;

    @include tablet {
        // height: 50vh;
        // width: auto;
        height: auto;
        width: 600px;
        object-fit: cover;
    }
}

.image-carousel .controls {
    margin: 20px auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    
}

.image-carousel .icon {
    cursor: pointer;
}